import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['loadable']

  connect() {
    document.addEventListener('turbo:submit-start', this.displayLoaders);
    // document.addEventListener('turbo:before-stream-render', this.hideLoaders);
  }

  displayLoaders = () => {
    this.loadableTargets?.forEach((element) => {
      try {
        element.dataset.loading = true;
      } catch (e) {
        console.warn(e);
      }
    });
  }
}
