import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('mouseover', (e) => this.mouseover(e));
    this.element.addEventListener('mouseout', (e) => this.mouseout(e));
    this.element.addEventListener('dblclick', (e) => this.dblclick(e));
  }

  mouseover(event) {
    const ref = event.target.closest('td').getAttribute('data-ref');
    if (ref) {
      const rows = this.element.querySelectorAll(`[data-ref='${ref}']`);
      this.highlight(event.target, rows);
    }
  }

  mouseout(event) {
    if (this.highlighting && event.target == this.highlighting.source)
      this.unhighlight();
  }

  dblclick(event) {
    const ref = event.target.closest('td').getAttribute('data-ref');
    if (ref) {
      const rows = this.element.querySelectorAll(`[data-ref='${ref}']`);
      if (rows.length > 0)
        rows[0].scrollIntoView({behavior: 'smooth'});
    }
  }

  highlight(source, rows) {
    this.unhighlight();
    this.highlighting = {
      source: source,
      rows: rows,
    };
    this.highlighting.rows.forEach(element => {
      element.classList.add('bg-sunshine-yellow-100');
    });
  }

  unhighlight() {
    if (this.highlighting) {
      this.highlighting.rows.forEach(element => {
        element.classList.remove('bg-sunshine-yellow-100');
      });
    }
  }
}
