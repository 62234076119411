import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="partners--preapplication"
export default class extends Controller {
  static targets = ["emailField", "submit"]

  connect() {
    this.updateSubmitDisabledState();
  }

  updateSubmitDisabledState() {
    if (!this.hasSubmitTarget) return;
    if (!this.hasEmailFieldTarget) return;
    this.submitTarget.disabled = this.emailFieldTarget.value?.length < 1;
  }
}
