import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="partners--side-summary-v2"
export default class extends Controller {
  static values = {
    formId: String
  }

  onContinueToPayment(event) {
    event.preventDefault();

    // here we have to fetch form from DOM because
    // referencing by target does not work with turbo stream updates
    const form = document.getElementById(this.formIdValue);
    form.dataset.turbo = false;
    form.submit();
  }
}
