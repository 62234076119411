// IMPORTANT: ensure that the stripe.js script is loaded in the layout file of this controller's element
import { Controller } from '@hotwired/stimulus';

const genericErrorMessage =
  'An error occurred. Please verify your information and try again. If issue persists, please contact support.';

// Connects to data-controller="stripe-payment-form"
export default class extends Controller {
  static targets = [
    'paymentElement',
    'submitButton',
    'stripeErrorMessage',
    'loadingIndicator',
    'acceptPaymentTermsCheckbox',
    'acceptPaymentTermsError',
    'paymentTermsSection'
  ];
  static values = {
    stripePublicKey: String,
    paymentIntentClientSecret: String,
    returnUrl: String,
    userId: String,
    submitMessage: String,
    processingMessage: String,
    redirectingMessage: String,
    showStripeLoader: Boolean,
    beforeConfirmPaymentActionData: Object,
    acceptPaymentTermsRequired: Boolean
  };

  initialize() {
    this.onSubmit.bind(this);

    this.logger = new RhinoLogger('Stimulus - StripePaymentFormController');
  }

  connect() {
    try {
      if (!this.stripePublicKeyValue) throw new Error('Missing stripePublicKey');
      if (!this.paymentIntentClientSecretValue) throw new Error('Missing paymentIntentClientSecret');

      this.stripe = Stripe(this.stripePublicKeyValue);
      if (!this.stripe) throw new Error('Stripe not found');

      this.elements = this.stripe.elements({
        clientSecret: this.paymentIntentClientSecretValue,
        loader: this.showStripeLoaderValue ? 'always' : 'never'
      });
      if (!this.elements) throw new Error('Stripe elements not found');

      this.stripePaymentElement = this.elements.create('payment');
      if (!this.stripePaymentElement) throw new Error('Stripe payment element not found');
      if (!this.paymentElementTarget) throw new Error('Stripe payment element target not found');
      this.stripePaymentElement.mount(this.paymentElementTarget);
    } catch (e) {
      return this.handleStripeLoadError(e);
    }

    if (this.loadingIndicatorTarget) {
      this.stripePaymentElement.on('ready', () => {
        this.loadingIndicatorTarget.animate({ opacity: [1, 0] }, { duration: 250, easing: 'ease-in-out' }).onfinish =
          () => this.loadingIndicatorTarget.remove();
      });
    }

    this.submitButtonTarget.addEventListener('click', (e) => this.onSubmit(e));
  }

  disconnect() {
    this.stripePaymentElement.destroy();
    this.submitButtonTarget.removeEventListener('click', this.onSubmit);
  }

  async onSubmit(e) {
    e.preventDefault();

    this.stripeErrorMessageTarget.classList.add('hidden');

    if (!this.validateAcceptPaymentTerms()) return;

    this.disableSubmitButton({
      processingMessage: this.processingMessageValue || 'Processing...'
    });

    if (
      this.beforeConfirmPaymentActionDataValue.key &&
      beforeConfirmPaymentActions[this.beforeConfirmPaymentActionDataValue.key]
    ) {
      const redirectCallback = await beforeConfirmPaymentActions[this.beforeConfirmPaymentActionDataValue.key]({
        ...this.beforeConfirmPaymentActionDataValue.args,
        submitButtonTarget: this.submitButtonTarget
      }).catch((error) => {
        this.logger.error(`${this.beforeConfirmPaymentActionDataValue.key} :: error: ${error} :: user_id: ${this.userIdValue}`);
        console.error(`${this.beforeConfirmPaymentActionDataValue.key} :: error: ${error} :: user_id: ${this.userIdValue}`);
      });

      if (redirectCallback) return redirectCallback();
    }

    // Enable for testing locally in order to simuluate a successful payment
    // if (true) {
    //   this.disableSubmitButton({
    //     processingMessage: this.redirectingMessageValue || 'Redirecting...'
    //   });
    //   return window.location.replace(this.returnUrlValue);
    // }

    await this.stripe
      .confirmPayment({
        elements: this.elements,
        confirmParams: { return_url: this.returnUrlValue },
        redirect: 'if_required'
      })
      .then((result) => {
        if (!result.error) {
          this.disableSubmitButton({
            processingMessage: this.redirectingMessageValue || 'Redirecting...'
          });
          return window.location.replace(this.returnUrlValue);
        }

        let errorMessage = result.error.message;

        if (result.error.payment_method?.card?.funding === 'prepaid') {
          errorMessage = 'We do not accept prepaid cards. Enter a credit or debit card.';
        }

        this.stripeErrorMessageTarget.textContent = errorMessage;

        this.stripeErrorMessageTarget.classList.remove('hidden');

        this.enableSubmitButton();
      })
      .catch((error) => {
        console.error('Stripe Confirm Payment error:', error);
        this.stripeErrorMessageTarget.textContent = genericErrorMessage;
        this.stripeErrorMessageTarget.classList.remove('hidden');
        this.enableSubmitButton();
      });
  }

  validateAcceptPaymentTerms() {
    if (!this.acceptPaymentTermsRequiredValue || !this.hasAcceptPaymentTermsCheckboxTarget) return true;
    this.toggleAcceptPaymentTermsError();
    return this.acceptPaymentTermsCheckboxTarget.checked;
  }

  toggleAcceptPaymentTermsError() {
    this.acceptPaymentTermsCheckboxTarget.checked
      ? this.acceptPaymentTermsErrorTarget.classList.add('hidden')
      : this.acceptPaymentTermsErrorTarget.classList.remove('hidden');
  }

  disableSubmitButton({ processingMessage } = { processingMessage: '' }) {
    this.submitButtonTarget.disabled = true;
    if (processingMessage) this.submitButtonTarget.textContent = processingMessage;
  }

  enableSubmitButton() {
    this.submitButtonTarget.disabled = false;
    this.submitButtonTarget.textContent = this.submitMessageValue || 'Submit Payment';
  }

  handleStripeLoadError(message) {
    this.logger.error(`Stripe failed to load: ${message}`);
    if (this.hasLoadingIndicatorTarget) this.loadingIndicatorTarget.classList.add("hidden");
    if (this.hasPaymentTermsSectionTarget) this.paymentTermsSectionTarget.classList.add("hidden");
    this.stripeErrorMessageTarget.classList.remove("hidden");
    this.stripeErrorMessageTarget.textContent = "Sorry, we're having an issue communicating with the payment system. Please try again later.";
  }
}

const beforeConfirmPaymentActions = {
  'data-sync-verification': async ({ endpoint, submitButtonTarget }) => {
    return new Promise((resolve, reject) => fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (!response.ok) return reject('Data Sync Verification Query failed. Response not OK.');
        if (!response.redirected)  return reject('Data Sync Verification Query failed. Response not a redirect.');
        if (!response.url)  return reject('Data Sync Verification Query failed. Missing redirect URL.');

        if (submitButtonTarget) {
          submitButtonTarget.disabled = true;
          submitButtonTarget.innerText = 'Redirecting...';
        }
        return resolve(() => window.location.replace(response.url));
      })
      .catch((error) => reject(error))
    );
  }
};
