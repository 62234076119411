import { Controller } from "@hotwired/stimulus"

const oneSecond = 1000;

export default class extends Controller {
  static targets = ['timer'];

  static values = {
    url: String,
    time: { type: Number, default: oneSecond * 3 },
  }

  connect() {
    if (this.hasUrlValue) this.timeoutRedirect();
    if (this.hasTimerTarget) this.runTimer();
  }

  timeoutRedirect() {
    setTimeout(() => {
      window.location.replace(this.urlValue);
    }, this.timeValue);
  }

  runTimer() {
    let timeLeft = this.timeValue;
    let interval = setInterval(() => {
      if (timeLeft > 0 ) {
        timeLeft -= oneSecond;
        this.timerTarget.innerText = this.timeLeftLabel(timeLeft);
      } else {
        clearInterval(interval);
      }
    }, oneSecond);
  }

  timeLeftLabel(time) {
    time = time / oneSecond;
    if (time === 0) return `a moment`;
    if (time === 1) return `${time} second`;

    return `${time} seconds`;
  }
}
