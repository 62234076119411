import { Controller } from "@hotwired/stimulus"
import {
  continueButtonDisabledStore,
  lemonadeSelectedStore
} from "../../stores/partnersProductSelection"
import { useStore } from "stimulus-store"

// Connects to data-controller="partners--lemonade-selection"
export default class extends Controller {
  static targets = [
    'acceptTermsCheckbox',
    'optInSection',
    'optOutSection'
  ]
  static stores = [
    continueButtonDisabledStore,
    lemonadeSelectedStore
  ]

  connect() {
    useStore(this)
    this.setLemonadeSelectedStoreValue(true)
    this.setContinueButtonDisabledStoreValue(true)
  }

  onOptIn() {
    this.setLemonadeSelectedStoreValue(true)
  }

  onOptOut() {
    this.setLemonadeSelectedStoreValue(false)
  }

  onLemonadeSelectedStoreUpdate(selected) {
    if (selected) {
      this.hasAcceptTermsCheckboxTarget && (this.acceptTermsCheckboxTarget.checked = false);
      this.hasOptInSectionTarget && (this.optInSectionTarget.classList.remove('hidden'));
      this.hasOptOutSectionTarget && this.optOutSectionTarget.classList.add('hidden')
      this.setContinueButtonDisabledStoreValue(true)
    } else {
      this.hasOptInSectionTarget && this.optInSectionTarget.classList.add('hidden')
      this.hasOptOutSectionTarget && this.optOutSectionTarget.classList.remove('hidden')
      this.setContinueButtonDisabledStoreValue(false)
    }
  }

  onAcceptTerms(event) {
    this.setContinueButtonDisabledStoreValue(!event.currentTarget.checked)
  }
}
