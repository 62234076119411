import { Controller } from '@hotwired/stimulus'
import { retryLoadGoogleMapsAPI } from 'utils/google';

export default class extends Controller {
  static targets = ['input', 'placeId'];

  initialize() {
    this.connect.bind(this);
  }

  connect() {
    if (typeof google === 'undefined') return this.retryLoad(this.connect);

    this.autocomplete = new google.maps.places.Autocomplete(this.inputTarget, this.autocompleteOptions);
    this.inputTarget.addEventListener('change', () => this.inputChanged());
    this.autocomplete.addListener('place_changed', () => this.placeChanged());
    this.element.addEventListener('keydown', (event) => this.keydown(event));
  }

  retryLoad(cb) {
    return retryLoadGoogleMapsAPI()
      .then(() => cb())
      .catch(() => console.error('Google Maps API not loaded.'));
  }

  inputChanged() {
    if (this.inputTarget.value !== this.inputForPlaceId) {
      this.placeIdTarget.value = '';
    }
  }

  placeChanged() {
    const place = this.autocomplete.getPlace();
    if (place && this.hasPlaceIdTarget) {
      this.placeIdTarget.value = place.place_id;
      this.inputForPlaceId = this.inputTarget.value;
    }
  }

  keydown(event) {
    if (event.keyCode === 13)
      event.preventDefault();
  }

  get autocompleteOptions() {
    return {
      types: ['address'],
      componentRestrictions: { country: 'US' },
    }
  }
}
