import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['addressLine', 'firstTimeRenter'];

  connect() {
    if (this.hasFirstTimeRenterTarget) {
      this.firstTimeRenterTarget.addEventListener('click', (e) => this.setAddressLinesDisabledness());
    }
    this.setAddressLinesDisabledness();
  }

  setAddressLinesDisabledness() {
    for (const input of this.addressLineTargets)
      input.disabled = this.firstTimeRenterTarget.checked;
  }
}
