import { Controller } from '@hotwired/stimulus';

// Enable submit button when checkbox is checked

// Connects to data-controller="accept-terms"
export default class extends Controller {
  static targets = ['submitButton'];

  toggle = (event) => {
    event.preventDefault();
    this.submitButtonTarget.disabled = !event.target.checked;
  };
}
