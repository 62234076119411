const colors = {
  'pitch-neutral': {
    100: '#000000',
    90: '#212526',
    80: '#40474A',
    70: '#5A6468',
    60: '#768389',
    50: '#ADB4B8',
    40: '#CBCFD2',
    30: '#DBDEE0',
    20: '#E4E6E7',
    10: '#F5F6F7',
    0: '#FFFFFF'
  },
  'raptor-purple': {
    125: '#4210D4',
    100: '#6A3BF5',
    75: '#916EF7',
    50: '#B69EFA',
    25: '#DACFFC',
    10: '#EDE7FE',
    5: '#F4F0FE'
  },
  'river-blue': {
    125: '#0E38D0',
    100: '#315BF1',
    75: '#6182F4',
    50: '#91A8F8',
    25: '#C1CEFB',
    10: '#DEE4FD',
    5: '#F1F4FE'
  },
  'barry-red': {
    125: '#B42D00',
    100: '#DB3700',
    75: '#FF531A',
    50: '#FF794C',
    25: '#FFB399',
    10: '#FFD9CC',
    5: '#FFECE5'
  },
  'bloom-green': {
    125: '#10652B',
    100: '#12873D',
    75: '#18B451',
    50: '#1EE166',
    25: '#8EF1AE',
    10: '#BBF6CE',
    5: '#E8FCEF'
  },
  'sunshine-yellow': {
    125: '#D6BB00',
    100: '#F8D904',
    75: '#F6F405',
    50: '#FCFB69',
    10: '#FDFDBE',
    5: '#FEFEE6'
  }
};

export default colors;
