import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['phone'];

  initialize() {
    if (this.hasPhoneTarget) {
      this.formatPhone()
    }
  }

  formatPhone() {
    const field = this.phoneTarget;
    let value = field.value;
    const matches = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    value = !matches[2] ? matches[1] : '(' + matches[1] + ') ' + matches[2] + (matches[3] ? '-' + matches[3] : '');

    field.value = value;
  }

  unformatPhone() {
    if (this.hasPhoneTarget) {
      const field = this.phoneTarget;
      field.value = field.value.replace(/\D/g, '');
    }
  }
}
