import { Controller } from  "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "passwordField",
    "shortLengthMessage",
    "requireNumbersMessage",
    "requireSymbolsMessage",
    "confirmPasswordField",
    "requireConfirmationMessage"
  ]

  validatePassword = () => {
    const field = this.passwordFieldTarget;
    const value = field.value;

    const longEnough = value.length >= 8;
    const containsNumbers = /\d/.test(value);
    const containsSymbols = /\W/.test(value);
    const valid = longEnough && containsNumbers && containsSymbols;

    this._validateConstraint(longEnough, this.shortLengthMessageTarget);
    this._validateConstraint(containsNumbers, this.requireNumbersMessageTarget);
    this._validateConstraint(containsSymbols, this.requireSymbolsMessageTarget);

    this._toggleInputState(valid, this.passwordFieldTarget);

    return valid;
  }

  confirmPassword = () => {
    const password = this.passwordFieldTarget.value;
    const confirmation = this.confirmPasswordFieldTarget.value;
    const valid = password === confirmation;

    this._toggleInputState(valid, this.confirmPasswordFieldTarget);
    this._validateConstraint(valid, this.requireConfirmationMessageTarget);

    return valid;
  }

  validateForm(event) {
    const passwordValid = this.validatePassword();
    const confirmed = this.confirmPassword();
    const valid = passwordValid && confirmed;

    if (!valid) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  _toggleInputState(enabled, field) {
    field.classList[enabled ? 'remove' : 'add']('has-error');
  }

  _validateConstraint(passed, field) {
    field.classList.toggle("hidden", passed)
  }
}
