import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    length: Number,
  };

  connect() {
    this.element.addEventListener('input', () => this.input());
    this.element.addEventListener('paste', (e) => this.pasted(e));
    this.element.focus();
  }

  input() {
    this.element.type = 'text';
    if (this.timeout)
      clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.element.type = 'password', 1000);
    if (this.element.value.length >= this.lengthValue)
      this.submitButton.click();
  }

  pasted(event) {
    event.preventDefault();
    const text = event.clipboardData.getData('text/plain').slice(0, this.lengthValue);
    this.element.value = text;
    this.input();
  }

  get submitButton() {
    return this.element.closest('form').querySelector('input[type="submit"]');
  }
}
