import { Controller } from "@hotwired/stimulus";
import { debounce } from "lodash";

export default class extends Controller {
  connect() {
    let that = this;
    that.element.addEventListener('change', debounce(that.handleChange, 300))
  }

  handleChange(event) {
    event.preventDefault();
    event.target.form.requestSubmit();
  }
}
