import { createStore } from 'stimulus-store';

const SDI = 'security_deposit_insurance';
const CASH = 'cash_deposit';

const productTypeStore = createStore({
  name: 'productTypeStore',
  type: String,
  initialValue: ''
});

const UPFRONT = 'upfront';
const MONTHLY = 'monthly';

const cadenceStore = createStore({
  name: 'cadenceStore',
  type: String,
  initialValue: ''
});

const continueButtonDisabledStore = createStore({
  name: 'continueButtonDisabledStore',
  type: Boolean,
  initialValue: false
})

const lemonadeSelectedStore = createStore({
  name: 'lemonadeSelectedStore',
  type: Boolean,
  initialValue: false
})

export {
  productTypeStore,
  cadenceStore,
  SDI,
  CASH,
  UPFRONT,
  MONTHLY,
  continueButtonDisabledStore,
  lemonadeSelectedStore,
}
