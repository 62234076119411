import { Controller } from  "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "forgotPasswordModal",
    "sentLinkModal"
  ]

  openModal(modal) {
    modal.classList.add("animate-fadeIn");
    modal.classList.remove("animate-fadeOut");
    modal.classList.toggle('invisible');
  }

  closeModal(modal) {
    modal.classList.remove("animate-fadeIn");
    modal.classList.add("animate-fadeOut");
    modal.classList.toggle('invisible');
  }

  openForgotPasswordModal() {
    const forgotPasswordModal = this.forgotPasswordModalTarget;
    this.openModal(forgotPasswordModal)
  }

  closeForgotPasswordModal() {
    const forgotPasswordModal = this.forgotPasswordModalTarget;
    this.closeModal(forgotPasswordModal)
  }

  openSentLinkModal() {
    const sentLinkModal = this.sentLinkModalTarget;
    this.openModal(sentLinkModal)
  }

  closeSentLinkModal() {
    const sentLinkModal = this.sentLinkModalTarget;
    this.closeModal(sentLinkModal)
  }
}