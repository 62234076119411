import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="rhino-offer-card"
export default class extends Controller {
  static values = { trackingId: String }
  static targets = [
    "dismissOverlay",
    "showDismissOverlay",
    "hideDismissOverlay"
  ]

  initialize() {
    this.localStorageKey = `${this.trackingIdValue}-dismissedRhinoOffers`
    this.containerEl = document.getElementById(`${this.trackingIdValue}-rhino-offer-list`)
  }

  connect() {
    this.checkOfferDismissed()
  }

  get dismissedOffers() {
    let dismissedOffers = window.localStorage.getItem(this.localStorageKey)
    return dismissedOffers ? JSON.parse(dismissedOffers) : []
  }

  set dismissedOffers(offers) {
    window.localStorage.setItem(this.localStorageKey, JSON.stringify(offers))
  }

  checkOfferDismissed() {
    this.dismissedOffers.find(offerId => {
      if (offerId === this.element.id) this.removeOfferCard()
    })
  }

  dismissOffer() {
    let offers = this.dismissedOffers
    offers.push(this.element.id)
    this.dismissedOffers = offers
    this.removeOfferCard()
  }

  removeOfferCard() {
    this.element.remove()

    // Hide the entire list if there are no more cards
    const remainingCards = this.containerEl.getElementsByClassName('rhino-offer-card')
    if (remainingCards.length === 0) this.containerEl.remove();
  }

  showDismissOverlay() {
    this.dismissOverlayTarget.classList.remove('hidden')
    this.hideDismissOverlayTarget.focus()
  }

  hideDismissOverlay() {
    this.dismissOverlayTarget.classList.add('hidden')
    this.showDismissOverlayTarget.focus()
  }
}
