// IMPORTANT: ensure that the stripe.js script is loaded in the layout file of this controller's element
import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

const genericErrorMessage =
  'An error occurred. Please verify your information and try again. If issue persists, please contact support.';

// Connects to data-controller="deferred-stripe-payment-form"
export default class extends Controller {
  static targets = [
    'paymentElement',
    'submitButton',
    'stripeErrorMessage',
    'loadingIndicator'
  ];
  static values = {
    stripePublicKey: String,
    returnUrl: String,
    submitMessage: String,
    processingMessage: String,
    redirectingMessage: String,
    policyApplicationId: String,
    totalDue: Number
  };

  initialize() {
    this.onSubmit.bind(this);
  }

  connect() {
    try {
      if (!this.stripePublicKeyValue) throw new Error('Missing stripePublicKey');
      if (!this.policyApplicationIdValue) throw new Error('Missing policyApplicationId');

      this.stripe = Stripe(this.stripePublicKeyValue);
      if (!this.stripe) throw new Error('Stripe not found');

      this.elements = this.stripe.elements({
        mode: 'payment',
        currency: 'usd',
        amount: this.totalDueValue,
        paymentMethodTypes: ['card', 'us_bank_account'],
      });

      if (!this.elements) throw new Error('Stripe elements not found');

      this.stripePaymentElement = this.elements.create('payment');
      if (!this.stripePaymentElement) throw new Error('Stripe payment element not found');
      if (!this.paymentElementTarget) throw new Error('Stripe payment element target not found');
      this.stripePaymentElement.mount(this.paymentElementTarget);
    } catch (e) {
      return this.handleStripeLoadError(e);
    }

    if (this.loadingIndicatorTarget) {
      this.stripePaymentElement.on('ready', () => {
        this.loadingIndicatorTarget.animate({ opacity: [1, 0] }, { duration: 250, easing: 'ease-in-out' }).onfinish =
          () => this.loadingIndicatorTarget.remove();
      });
    }

    this.submitButtonTarget.addEventListener('click', (e) => this.onSubmit(e));
  }

  disconnect() {
    this.stripePaymentElement.destroy();
    this.submitButtonTarget.removeEventListener('click', this.onSubmit);
  }

  async onSubmit(e) {
    e.preventDefault();

    this.stripeErrorMessageTarget.classList.add('hidden');

    this.disableSubmitButton({
      processingMessage: this.processingMessageValue || 'Processing...'
    });

    const clientSecret = await this.createPaymentIntent();
    const {error: submitError} = await this.elements.submit();
    if (submitError) {
      this.stripeErrorMessageTarget.textContent = genericErrorMessage;
      this.stripeErrorMessageTarget.classList.remove('hidden');
      this.enableSubmitButton();
      return;
    }

    if (!clientSecret) {
      this.stripeErrorMessageTarget.textContent = genericErrorMessage;
      this.stripeErrorMessageTarget.classList.remove('hidden');
      this.enableSubmitButton();
      return;
    }

    await this.stripe
      .confirmPayment({
        elements: this.elements,
        clientSecret,
        confirmParams: { return_url: this.returnUrlValue },
        redirect: 'if_required'
      })
      .then((result) => {
        if (!result.error) {
          this.disableSubmitButton({
            processingMessage: this.redirectingMessageValue || 'Redirecting...'
          });
          return window.location.replace(this.returnUrlValue);
        }

        let errorMessage = result.error.message;

        if (result.error.payment_method?.card?.funding === 'prepaid') {
          errorMessage = 'We do not accept prepaid cards. Enter a credit or debit card.';
        }

        this.stripeErrorMessageTarget.textContent = errorMessage;

        this.stripeErrorMessageTarget.classList.remove('hidden');

        this.enableSubmitButton();
      })
      .catch((error) => {
        console.error('Stripe Confirm Payment error:', error);
        this.stripeErrorMessageTarget.textContent = genericErrorMessage;
        this.stripeErrorMessageTarget.classList.remove('hidden');
        this.enableSubmitButton();
      });
  }

  disableSubmitButton({ processingMessage } = { processingMessage: '' }) {
    this.submitButtonTarget.disabled = true;
    if (processingMessage) this.submitButtonTarget.textContent = processingMessage;
  }

  enableSubmitButton() {
    this.submitButtonTarget.disabled = false;
    this.submitButtonTarget.textContent = this.submitMessageValue || 'Submit Payment';
  }

  handleStripeLoadError(_message) {
    if (this.hasLoadingIndicatorTarget) this.loadingIndicatorTarget.classList.add("hidden");
    this.stripeErrorMessageTarget.classList.remove("hidden");
    this.stripeErrorMessageTarget.textContent = "Sorry, we're having issues communicating with the payment system. Please try again later.";
  }

  async createPaymentIntent() {
    const DEFAULT_HEADERS = {
      accept: 'application/json',
      'Content-Type': 'application/json'
    };

    const DEFAULT_OPTIONS = {
      authenticity_token: document.head.querySelector("[name=csrf-token]").content,
      credentials: 'same-origin',
      headers: DEFAULT_HEADERS
    };

    try {
      const response = await axios.post(
        `/policy_applications/${this.policyApplicationIdValue}/create_new_payment_intent`,
        DEFAULT_OPTIONS
      );
      if (response.status !== 201) throw new Error('Failed to create payment intent');
      return response.data.client_secret;
    } catch (_error) {
      return null;
    }
  }
}
