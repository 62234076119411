import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="partners--product-selection-v2"
export default class extends Controller {
  static targets = ['form', 'blankPrice']

  connect() {
    this.formTarget?.addEventListener('change', this.handleFormChange);
  }

  handleFormChange = (event) => {
    if (event.target?.id === 'lemonade_quote_tos_approved') return;

    this.blankPriceTargets?.forEach((element) => {
      element.innerHTML = "$--.--";
    });
    document.querySelectorAll('[data-price="total-due"]')?.forEach((element) => {
      element.innerHTML = "$--.--";
    });
  }

  onClickNext(event) {
    event.preventDefault();

    this.formTarget.dataset.turbo = false;
    this.formTarget.submit();
  }
}
