// IMPORTANT: ensure that the stripe.js script is loaded in the layout file of this controller's element
import { Controller } from '@hotwired/stimulus';
import colors from '../../../config/tailwind/colors';

const genericErrorMessage =
  'An error occurred. Please verify your information and try again. If issue persists, please contact support.';
const paymentSystemErrorMessage =
  "Sorry, we're having an issue communicating with the payment system.";

// Connects to data-controller="lemonade-stripe-payment-form"
export default class extends Controller {
  static targets = [
    'cardElement',
    'submitButton',
    'errorMessageContainer',
    'errorMessageContent',
    'errorMessageUnblock'
  ];
  static values = {
    stripePublicKey: String,
    lemonadeQuoteId: String,
    lemonadePaymentUrl: String,
    returnUrl: String,
    submitMessage: String,
    processingMessage: {
      type: String,
      default: 'Processing...'
    },
    redirectingMessage: {
      type: String,
      default: 'Redirecting...'
    }
  };

  initialize() {
    this.logger = new RhinoLogger('Stimulus - LemonadeStripePaymentFormController');
  }

  connect() {
    try {
      if (!this.stripePublicKeyValue) throw new Error('Missing stripePublicKey');

      this.stripe = Stripe(this.stripePublicKeyValue);
      if (!this.stripe) throw new Error('Stripe not found');

      this.elements = this.stripe.elements({ loader: 'always' });
      if (!this.elements) throw new Error('Stripe elements not found');

      this.stripeCardElement = this.elements.create('card', {
        disableLink: true,
        style: {
          base: {
            iconColor: colors['raptor-purple'][100],
            color: colors['pitch-neutral'][90],
            fontWeight: '500',
            fontFamily: 'Lato, sans-serif',
            fontSize: '18px',
            lineHeight: '24px',
            fontSmoothing: 'antialiased',
            '::placeholder': {
              color: colors['pitch-neutral'][50],
            },
          }
        }
      });
      if (!this.stripeCardElement) throw new Error('Stripe card element not found');
      if (!this.cardElementTarget) throw new Error('Stripe payment element target not found');
      this.stripeCardElement.mount(this.cardElementTarget);
    } catch (e) {
      return this.handleRequestError(e);
    }

    this.stripeCardElement.on('ready', () => {
      this.cardElementTarget.classList.remove('animate-pulse', 'bg-pitch-neutral-10');
    });
  }

  disconnect() {
    this.stripePaymentElement.destroy();
  }

  requestStripeCardToken(e) {
    e.preventDefault();

    this.errorMessageContainerTarget.classList.add('hidden');
    this.errorMessageUnblockTarget.classList.add('hidden');

    this.disableSubmitButton({
      processingMessage: this.processingMessageValue
    });

    // Enable for testing locally in order to simuluate a successful payment
    // if (true) {
    //   this.disableSubmitButton({
    //     processingMessage: this.redirectingMessageValue
    //   });
    //   return setTimeout(() => window.location.replace(this.returnUrlValue), 2000);
    // }

    return this.stripe.createToken(this.stripeCardElement)
      .then((result) => {
        if (result && !result.error) {
          if (result.token.error) return this.handleRequestError(result.token.error.message)
          if (result.token.used) return this.handleRequestError('Lemonade Stripe Card Token already used.')

          return this.requestLemonadePayment(result.token.id)
        }

        let errorMessage = result.error.message;

        if (result.error.payment_method?.card?.funding === 'prepaid') {
          errorMessage = 'We do not accept prepaid cards. Enter a credit or debit card.';
        }

        this.errorMessageContentTarget.textContent = errorMessage;

        this.errorMessageContainerTarget.classList.remove('hidden');
        this.errorMessageUnblockTarget.classList.add("hidden");

        this.enableSubmitButton();
      })
      .catch((error) => {
        this.logger.error(`requestStripeCardToken error: ${error}`);
        this.errorMessageContentTarget.textContent = paymentSystemErrorMessage;
        this.errorMessageContainerTarget.classList.remove('hidden');
        this.enableSubmitButton();
      });
  }

  requestLemonadePayment(card_token_id) {
    // we can assume tos_approved is true because tos acceptance is required
    // in order to continue to payment after opting in to Lemonade
    const body = {
      id: this.lemonadeQuoteIdValue,
      payment: {
        card_token_id,
        tos_approved: true
      }
    }

    return fetch(this.lemonadePaymentUrlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.getCsrfToken()
      },
      body: JSON.stringify(body)
    }).then(response => {
      if (response.ok) return response.json();
      return response.json()
        .then(errorData => Promise.reject(new Error(errorData.message || 'Payment failed')))
        .catch(error => Promise.reject(new Error(`Unable to parse response json: ${error}`)));
    }).then(data => {
      if (!data.policy_id) return Promise.reject(new Error(`Missing policy_id: ${data}`));

      this.disableSubmitButton({
        processingMessage: this.redirectingMessageValue
      });

      return window.location.replace(this.returnUrlValue);
    }).catch(error => {
      this.handleRequestError(`requestLemonadePayment failed: ${error}`);
    });
  }

  disableSubmitButton({ processingMessage } = { processingMessage: '' }) {
    this.submitButtonTarget.disabled = true;
    if (processingMessage) this.submitButtonTarget.textContent = processingMessage;
  }

  enableSubmitButton() {
    this.submitButtonTarget.disabled = false;
    this.submitButtonTarget.textContent = this.submitMessageValue || 'Submit Payment';
  }

  handleRequestError(error) {
    this.logger.error(`handleRequestError error: ${error}`);
    this.errorMessageContainerTarget.classList.remove("hidden");
    this.errorMessageUnblockTarget.classList.remove("hidden");
    this.errorMessageContentTarget.textContent = paymentSystemErrorMessage
    this.disableSubmitButton({
      processingMessage: this.submitMessageValue
    });
  }

  getCsrfToken() {
    const tokenMeta = document.head.querySelector("[name=csrf-token]");
    return tokenMeta && tokenMeta.content;
  }
}
