import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="lemonade-offer-button"
export default class extends Controller {
  static values = {
    trackingId: String
  }

  openOfferInNewTab() {
    window.open(
      `https://go.lemonade.com/visit/?bta=36554&nci=5696&afp=${this.trackingIdValue}`,
      "_blank"
    );
  }
}
